import {
	addToCart,
	deleteCart,
	getCartCount,
	editCartNum
} from '@/api/goods/cart'
import {getToken} from "@/utils/auth";

const state = {
	cartCount: 0
}

const mutations = {
	SET_CART_COUNT: (state, count) => {
		state.cartCount = count
	}
}

const actions = {
	add_to_cart({
		commit
	}, item) {
		return new Promise((resolve, reject) => {
			return addToCart({
					source_url: item.source_url,
					site_id: item.site_id,
					num: item.num || 1,
					website_id: ("website_id" in item)?item.website_id : 0,
					source_type: ("source_type" in item)?item.source_type : 0,
					sku_id: item.sku_id
				}).then(res => {
					getCartCount({
						website_id: ("website_id" in item)?item.website_id : 0,
						source_type: ("source_type" in item)?item.source_type : 0,
					}).then(res => {
						if(res.code == 0) {
							commit('SET_CART_COUNT', res.data)
							resolve(res)
						}
					});
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	delete_cart({
		commit
	}, item) {
		return new Promise((resolve, reject) => {
			return deleteCart({
				cart_id: item.cart_id
			}).then(res => {
				getCartCount({
					website_id: ("website_id" in item)?item.website_id : 0,
					source_type: ("source_type" in item)?item.source_type : 0,
				}).then(res => {
					if(res.code == 0) {
						commit('SET_CART_COUNT', res.data)
						resolve(res)
					}
				});
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	cart_count({
		commit
	}, item) {
		if(item){
			return new Promise((resolve, reject) => {
				if(getToken() !== undefined) {
					return getCartCount({
						website_id: ("website_id" in item) ? item.website_id : 0,
						source_type: ("source_type" in item) ? item.source_type : 0,
					}).then(res => {
						if (res.code == 0) {
							commit('SET_CART_COUNT', res.data)
							resolve(res)
						}
					}).catch(err => {
						reject(err)
					})
				}
			})
		}else{
			return new Promise((resolve, reject) => {
				if(getToken() !== undefined) {
					return getCartCount({}).then(res => {
						if (res.code == 0) {
							commit('SET_CART_COUNT', res.data)
							resolve(res)
						}
					}).catch(err => {
						reject(err)
					})
				}
			})
		}

	},
	edit_cart_num({
		commit
	}, item) {
		return new Promise((resolve, reject) => {
			return editCartNum({
				num: item.num,
				cart_id: item.cart_id
			}).then(res => {
				getCartCount({
					website_id: ("website_id" in item)?item.website_id : 0,
					source_type: ("source_type" in item)?item.source_type : 0,
				}).then(res => {
					if(res.code == 0) {
						commit('SET_CART_COUNT', res.data)
						resolve(res)
					}
				});
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
